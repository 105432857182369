<template>
  <div>
    <div class="header">
      <el-menu
        :default-active="defaultOpen"
        active-text-color="#409eff"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="item in menu"
          :key="item.index"
          :index="item.index"
          :class="{ active: defaultOpen === item.index }"
          >{{ item.label }}</el-menu-item
        >
      </el-menu>
      <div class="publish">
        <el-button
          v-show="this.$route.path === '/admin/design/formSetting'"
          size="mini"
          type="primary"
          @click="publish"
          ><i class="el-icon-s-promotion"></i>保存</el-button
        >
      </div>
      <div class="back">
        <el-button
          @click="exit"
          size="medium"
          icon="el-icon-arrow-left"
          circle
        ></el-button>
        <span>
          <span style="margin-left: 10px">{{ setup.formName }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutHeader",

  data() {
    return {
      defaultOpen: "formSetting",
      menu: [
        { index: "formSetting", label: "① 表单设计" },
        { index: "processDesign", label: "② 流程设计" },
        { index: "tableDesign", label: "③ 列表设计" },
        { index: "formProSetting", label: "④ 高级设置" },
      ],
    };
  },
  computed: {
    setup() {
      return this.$store.state.design;
    },
  },
  mounted() {
    this.defaultOpen = this.$route.path.split("/")[3];
  },
  methods: {
    publish() {
      this.$emit("publish");
    },

    exit() {
      this.$confirm("未发布的内容将不会被保存，是否直接退出 ?", "提示", {
        confirmButtonText: "退出",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.state.selectFormItem = null;
        if (this.$route.query.code) {
          this.$router.push(`/home?code=${this.$route.query.code}`);
        } else {
          this.$router.push(`/home`);
        }
      });
    },
    handleSelect(key) {
      if (this.$route.path.includes(key)) return;
      this.$confirm("未发布的内容将不会被保存，是否切换页面 ?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.push({
          path: key,
          query: this.$route.query,
        });
        this.defaultOpen = key;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .header {
  min-width: 980px;
  position: relative;

  .el-menu {
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .publish {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;

    i {
      margin-right: 6px;
    }

    button {
      border-radius: 15px;
    }
  }

  .back {
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 20px;
    font-size: small;

    span {
      i {
        border-radius: 10px;
        padding: 7.8px;
        font-size: 20px;
        color: #ffffff;
        margin: 0 10px;
      }
    }
  }
}

::v-deep {
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none !important;
    color: none !important;
  }
  .el-menu--horizontal > .el-menu-item.active {
    border-bottom: 2px solid blue !important;
    color: blue !important;
  }
}
</style>
